import css from './NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <>
      <h3>Page Not Found ...</h3>
    </>
  );
}

export default NotFoundPage;
